import * as React from 'react';
import { Box, Card, List, ListItemButton, ListItemText } from '@mui/material';
// import { MultipleChoice } from '../modules/assignments/MultipleChoice';
import { ModulePage } from '../interfaces/Interfaces';
import { defaultTheme } from '../utilities/Utilites';

interface Props {
    pages: Array<ModulePage>;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    viewModuleDetails: number;
}

const ModuleTableOfContents = ({
    pages,
    currentPage,
    setCurrentPage,
    viewModuleDetails,
}: Props) => {
    console.log('viewModuleDetails', viewModuleDetails);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        event.preventDefault();
        setCurrentPage(index);
    };

    const contentListItems = () => {
        return pages?.map((page, i) => {
            return (
                <ListItemButton
                    selected={currentPage === i}
                    onClick={(event) => handleListItemClick(event, i)}
                    key={'TOC-List-Item-' + i}
                    sx={{
                        '&.Mui-selected': {
                            borderStyle: 'groove',
                            borderWidth: '2px',
                            borderLeft: `5px solid ${defaultTheme.palette.primary.main}`,
                        },
                    }}
                >
                    <ListItemText primary={page.title} />
                </ListItemButton>
            );
        });
    };

    return (
        <Card
            sx={{
                height: '100%',
                borderRadius: '0px',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                }}
            >
                <List component="nav" aria-label="secondary mailbox folder">
                    {contentListItems()}
                </List>
            </Box>
        </Card>
    );
};

export { ModuleTableOfContents };
