import * as React from 'react';
import Card from '@mui/material/Card';
import { Class } from '../interfaces/Interfaces';

interface Props {
    handleToggleModulePage: () => void;
    module: Class;
}

const ClassPreviewCard = ({ handleToggleModulePage, module }: Props) => {
    const getColor = () => {
        return (
            'hsl(' +
            360 * Math.random() +
            ',' +
            (25 + 70 * Math.random()) +
            '%,' +
            75 +
            '%)'
        );
    };
    const backImg = module?.image?.length ? module.image : '';
    return (
        <Card
            onClick={handleToggleModulePage}
            style={{
                boxShadow:
                    '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            }}
            sx={{
                margin: '10px',
                width: '300px',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                // backgroundImage: backImg as string,
            }}
        >
            <div
                style={
                    backImg
                        ? {
                              backgroundImage: 'url(' + backImg + ')',
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              height: '50%',
                          }
                        : {
                              height: '50%',
                              backgroundColor: getColor(),
                          }
                }
            />
            <div
                style={{
                    backgroundColor: 'white',
                    height: '50%',
                }}
            >
                <div
                    style={{
                        margin: '10px 10px 10px 10px',
                        fontWeight: 'bolder',
                        textAlign: 'center',
                    }}
                >
                    {module?.className}
                </div>
                <div className="Preview-Card" title={module?.classDescription}>
                    {module?.classDescription}
                </div>
            </div>
        </Card>
    );
};

export { ClassPreviewCard };
