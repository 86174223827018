import * as React from 'react';
import { Button, Card, Grid, IconButton, ThemeProvider } from '@mui/material';
// import { MultipleChoice } from '../modules/assignments/MultipleChoice';
import {
    Module,
    ModulePage,
    Question,
    UserAnswers,
} from '../interfaces/Interfaces';
import { ModulePreviewCard } from '../modules/ModulePreviewCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { MultipleChoice } from '../modules/assignments/MultipleChoice';
import useExtensions from '../utilities/RichTextEditor/useExtensions';
import { RichTextReadOnly } from 'mui-tiptap';
import { useGlobalStore } from '../utilities/GlobalState';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {
    defaultTheme,
    formatDateWithTime2,
    getHeaderIcon,
} from '../utilities/Utilites';
import { Datagrid } from '../utilities/Datagrid';
import { DateTime } from 'luxon';
// import GradingIcon from '@mui/icons-material/Grading';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { ModuleTableOfContents } from './ModuleTableOfContents';

interface Props {
    questions: Array<Question>;
    modules: Array<Module>;
    pages: Array<ModulePage>;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    getModulePages: (id: number) => Promise<Array<ModulePage>>;
    setPages: (pages: Array<ModulePage>) => void;
    disableForward: boolean;
    setDisableForward: (disable: boolean) => void;
    classID: number | undefined;
    instructorID?: number | undefined;
    toggleBack?: () => void;
    setViewModuleDetailsParent?: (module: number) => void;
    showGradebook?: (studentID?: number, moduleID?: number) => void;
    cardView?: boolean;
    selectedModule?: number;
    classTitle?: string;
}

const AssignmentsTab = ({
    modules,
    pages,
    currentPage,
    setCurrentPage,
    getModulePages,
    setPages,
    disableForward,
    setDisableForward,
    classID,
    instructorID,
    toggleBack,
    setViewModuleDetailsParent,
    showGradebook,
    cardView,
    selectedModule,
    classTitle,
}: Props) => {
    const [viewModuleDetails, setViewModuleDetails] = React.useState<number>(0);
    const [showSubmitButton, setShowSubmitButton] =
        React.useState<boolean>(false);
    const [questions, setQuestions] = React.useState<Array<Question>>();
    const [answers, setAnswers] = React.useState<Array<UserAnswers | null>>([]);
    const globalState = useGlobalStore((state) => state);
    const extensions = useExtensions({
        placeholder: 'Add your own content here...',
    });

    const moduleTitle = () => {
        return modules?.filter((module) => module?.id === viewModuleDetails)[0]
            ?.title;
    };

    const getPageByNumber = (pageNumber: number): ModulePage => {
        return pages.filter((page) => {
            return Number(page.pageNumber) === pageNumber + 1;
        })[0];
    };

    const toggleShowGradebook = (studentID?: number, moduleID?: number) => {
        if (showGradebook) {
            showGradebook(studentID, moduleID);
        }
    };

    React.useEffect(() => {
        if (getPageByNumber(currentPage)?.data?.startsWith('{"questions":')) {
            const quests: any = {
                ...JSON.parse(getPageByNumber(currentPage).data || ''),
            };
            setQuestions(quests?.questions);
        } else {
            setDisableForward(false);
        }
    }, [currentPage]);

    const checkModuleProgress = (moduleID: number, modulePageID: number) => {
        const progress = AuthPost(
            '/progress/getuserprogress',
            {
                classID: classID,
                userID: globalState?.user?.id,
                moduleID: moduleID,
                modulePageID: modulePageID,
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
        return progress.then((res) => {
            return res.recordset;
        });
    };

    // make explicit with button
    React.useEffect(() => {
        const isAssessment =
            getPageByNumber(currentPage)?.data?.startsWith('{"questions":') &&
            questions?.length;
        checkModuleProgress(
            Number(pages?.[currentPage]?.moduleId),
            pages?.[currentPage]?.id,
        ).then((progress) => {
            const completed = progress?.every(
                (obj: any) => obj?.status === 'complete',
            );
            if (
                currentPage + 1 === pages?.length &&
                !isAssessment &&
                !completed
            ) {
                setShowSubmitButton(true);
            }
        });
    }, [currentPage]);
    const completeModule = () => {
        AuthPost(
            '/progress/updateprogress',
            {
                classID: classID,
                userID: globalState?.user?.id,
                moduleID: Number(pages?.[currentPage]?.moduleId),
                instructorID: instructorID,
                status: 'complete',
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    if (setViewModuleDetailsParent) {
                        setViewModuleDetailsParent(0);
                        setViewModuleDetails(0);
                    }
                    return res.data;
                } else {
                    if (setViewModuleDetailsParent) {
                        setViewModuleDetailsParent(0);
                        setViewModuleDetails(0);
                    }
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
    };

    const setModuleProgressStatus = (
        moduleID: number,
        modulePageID: number,
        status: string,
    ) => {
        return AuthPost(
            '/progress/addprogress',
            {
                classID: classID,
                userID: globalState?.user?.id,
                moduleID: moduleID,
                modulePageID: modulePageID,
                status: status,
            },
            {
                'x-access-token': globalState?.authToken || '',
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200 || res?.status === 204) {
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                console.error(err.toString());
                toast.error(err.toString());
            });
    };

    const handleToggleModulePage = (modID: number) => {
        if (setViewModuleDetailsParent) {
            setViewModuleDetailsParent(modID);
        }
        setViewModuleDetails(modID);
        globalState.setCurrentClass(classID);
        getModulePages(modID).then((res) => {
            const progress = () =>
                checkModuleProgress(modID, res?.[currentPage]?.id);
            progress().then((res1) => {
                if (!res1?.length) {
                    setModuleProgressStatus(
                        modID,
                        res?.[currentPage]?.id,
                        'started',
                    );
                }
            });
            setPages(
                res?.sort(
                    (a: any, b: any) =>
                        Number(a.pageNumber) - Number(b.pageNumber),
                ),
            );
        });
        setCurrentPage(0);
    };

    React.useEffect(() => {
        if (selectedModule) {
            handleToggleModulePage(selectedModule);
        }
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container>
                {viewModuleDetails > 0 && getPageByNumber(currentPage) ? (
                    <Grid container>
                        <IconButton
                            onClick={() => {
                                if (currentPage > 0) {
                                    setCurrentPage(currentPage - 1);
                                } else if (currentPage === 0 && toggleBack) {
                                    toggleBack();
                                    setViewModuleDetails(0);
                                } else {
                                    setCurrentPage(0);
                                }
                            }}
                            sx={{
                                backgroundColor: 'rgba(192, 192, 192,0.7)',
                                position: 'fixed',
                                top: 'calc(50% + 80px)',
                                left: '50px',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                gap: '10px',
                                zIndex: '1000',
                                ':hover': {
                                    backgroundColor: 'rgba(192, 192, 192, 1)',
                                },
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                if (currentPage + 1 < pages?.length) {
                                    setCurrentPage(currentPage + 1);
                                }
                            }}
                            disabled={disableForward}
                            sx={{
                                backgroundColor: 'rgba(192, 192, 192,0.7)',
                                position: 'fixed',
                                top: 'calc(50% + 80px)',
                                right: '10px',
                                transform: 'translate(-50%, -50%)',
                                display: 'flex',
                                gap: '10px',
                                zIndex: '1000',
                                ':hover': {
                                    backgroundColor: 'rgba(192, 192, 192, 1)',
                                },
                            }}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                        <Grid
                            xs={2}
                            sx={{
                                textAlignLast: 'center',
                                boxShadow:
                                    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <div className="Class-Title">{classTitle}</div>
                        </Grid>
                        <Grid xs={1}>
                            {getHeaderIcon(pages?.[currentPage]?.headerIcon)}
                        </Grid>
                        <Grid
                            sx={{
                                textAlignLast: 'left',
                            }}
                            xs={9}
                        >
                            <div className="Module-Title">{moduleTitle()}</div>
                        </Grid>
                        <Grid
                            xs={2}
                            sx={{
                                textAlignLast: 'left',
                            }}
                        >
                            <ModuleTableOfContents
                                pages={pages}
                                currentPage={currentPage || 0}
                                setCurrentPage={setCurrentPage}
                                viewModuleDetails={viewModuleDetails || 0}
                            />
                        </Grid>
                        <Grid xs={10}>
                            <Card
                                style={{
                                    width: '100%',
                                }}
                            >
                                {getPageByNumber(currentPage)?.data?.startsWith(
                                    '{"questions":',
                                ) && questions?.length ? (
                                    <MultipleChoice
                                        questions={questions}
                                        setAnswers={setAnswers}
                                        answers={answers}
                                        page={getPageByNumber(currentPage)}
                                        setDisableForward={setDisableForward}
                                        classID={classID}
                                        setViewModuleDetails={
                                            setViewModuleDetails
                                        }
                                        finalPage={
                                            currentPage + 1 === pages?.length
                                        }
                                        // gradebookID={}
                                    />
                                ) : (
                                    <div style={{ margin: '15px' }}>
                                        {showSubmitButton ? (
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 3, mb: 2 }}
                                                onClick={completeModule}
                                            >
                                                SUBMIT TO BE GRADED
                                            </Button>
                                        ) : (
                                            <></>
                                        )}
                                        <RichTextReadOnly
                                            content={
                                                getPageByNumber(currentPage)
                                                    ?.data
                                            }
                                            extensions={extensions}
                                        />
                                    </div>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                ) : (
                    <></>
                )}
                {viewModuleDetails === 0 &&
                    (cardView ? (
                        <>
                            <Card
                                onClick={toggleBack}
                                sx={{
                                    width: '300px',
                                    height: '300px',
                                    margin: '10px',
                                    textAlign: 'center',
                                    boxShadow:
                                        '0px 2px 11px -1px rgba(0,0,0,4.8),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                            >
                                <ArrowCircleLeftIcon
                                    key={'blog-grid-slider-left-icon'}
                                    sx={{
                                        paddingTop: '65px',
                                        width: '150px',
                                        height: '150px',
                                        color: `${defaultTheme.palette.primary.main}`,
                                    }}
                                />
                            </Card>
                            {modules.map((module) => {
                                return (
                                    <ModulePreviewCard
                                        key={module.id}
                                        handleToggleModulePage={() =>
                                            handleToggleModulePage(module.id)
                                        }
                                        module={module}
                                        showGradebook={() =>
                                            toggleShowGradebook(
                                                Number(globalState?.user?.id),
                                                module.id,
                                            )
                                        }
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <>
                            {Datagrid({
                                sx: { height: 'unset' },
                                editMode: 'row',
                                columns: [
                                    {
                                        field: 'code',
                                        headerName: 'Code',
                                        type: 'string',
                                        flex: 0.25,
                                    },
                                    {
                                        field: 'title',
                                        headerName: 'Title',
                                        type: 'string',
                                        flex: 1,
                                    },
                                    {
                                        field: 'description',
                                        headerName: 'Description',
                                        type: 'string',
                                        flex: 1,
                                    },
                                    {
                                        field: 'active',
                                        headerName: 'Published',
                                        type: 'boolean',
                                        flex: 1,
                                    },
                                    {
                                        field: 'publishDate',
                                        headerName: 'Publish Date',
                                        type: 'string',
                                        flex: 1,
                                        renderCell: (rowData) => {
                                            const date = DateTime.fromISO(
                                                rowData.row.publishDate as any,
                                            ) as any;
                                            return rowData?.row?.publishDate
                                                ? formatDateWithTime2(date)
                                                : 'Unpublished';
                                        },
                                    },
                                    {
                                        field: 'action',
                                        headerName: 'Action',
                                        flex: 1,
                                        sortable: false,
                                        renderCell: (rowData: any) => {
                                            return (
                                                <>
                                                    <IconButton
                                                        sx={{
                                                            marginLeft: '5px',
                                                            backgroundColor:
                                                                defaultTheme
                                                                    .palette
                                                                    .primary
                                                                    .main,
                                                            border: 'solid #fff 1px',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                            fontSize: '20px',
                                                        }}
                                                        title="View"
                                                        onClick={() => {
                                                            if (
                                                                setViewModuleDetailsParent
                                                            ) {
                                                                setViewModuleDetailsParent(
                                                                    rowData?.row
                                                                        ?.id,
                                                                );
                                                            }
                                                            setViewModuleDetails(
                                                                rowData?.row
                                                                    ?.id,
                                                            );
                                                            globalState.setCurrentClass(
                                                                classID,
                                                            );
                                                            getModulePages(
                                                                rowData?.row
                                                                    ?.id,
                                                            ).then((res) =>
                                                                setPages(
                                                                    res?.sort(
                                                                        (
                                                                            a: any,
                                                                            b: any,
                                                                        ) =>
                                                                            Number(
                                                                                a.pageNumber,
                                                                            ) -
                                                                            Number(
                                                                                b.pageNumber,
                                                                            ),
                                                                    ),
                                                                ),
                                                            );
                                                            setCurrentPage(0);
                                                        }}
                                                    >
                                                        <WysiwygIcon />
                                                    </IconButton>
                                                </>
                                            );
                                        },
                                    },
                                ],
                                data: modules,
                            })}
                        </>
                    ))}
            </Grid>
        </ThemeProvider>
    );
};

export { AssignmentsTab };
