import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { AxiosError, AxiosResponse } from 'axios';
import { AuthPost } from '../utilities/Routing/Requests';
import { Copyright, defaultTheme } from '../utilities/Utilites';
import * as React from 'react';
import { useGlobalStore } from '../utilities/GlobalState';
import { FormHelperText, Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { toast } from 'react-toastify';
import customHistory from '../utilities/Routing/History';
import { useParams } from 'react-router-dom';

export const Login = () => {
    const globalState = useGlobalStore((state) => state);
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [message, setMessage] = React.useState('');
    React.useEffect(() => {
        if (open) {
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [open]);

    const forgotPassword = (e: any) => {
        e.preventDefault();
        if (email?.length) {
            AuthPost('/user/passwordReset', { Email: email });
            toast.success('Check your email for password reset instructions.');
        } else {
            setEmailError(true);
        }
    };

    const validateEmail = (emailInput: string) => {
        const res = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return res.test(String(emailInput).toLowerCase());
    };

    const { params } = useParams();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const username: string = event.currentTarget.username.value;
        const password: string = event.currentTarget.password.value;
        const body = JSON.stringify({
            Email: username,
            Password: password,
        });
        const isEmailValid = validateEmail(username);
        if (!isEmailValid) {
            setEmailError(true);
        } else if (username && password) {
            AuthPost('/user/login', body)
                .then((res: AxiosResponse) => {
                    if (res?.status === 200) {
                        globalState.clearState();
                        useGlobalStore.persist.clearStorage();

                        // globalState.setCustomerId(res.data.Customer_ID);
                        globalState.setAuthToken(res.data.token);
                        globalState.setIsAdmin(
                            res.data.type.includes('APTAdmin'),
                        );
                        globalState.setAuthenticated(true, res.data.userEmail);
                        globalState.setUser(res.data);
                        globalState.setEnv(res.data.envs);
                        return res;
                    } else {
                        globalState.setAuthUsername('');
                        globalState.setAuthenticated(false, '');
                        setMessage('Credentials are invalid!');
                        setOpen(true);
                    }
                })
                .then((resp) => {
                    globalState.setAuthUsername(resp?.data.userEmail);
                    if (resp?.data?.tempPassword) {
                        customHistory.push('/passwordreset');
                    } else {
                        customHistory.push(params?.length ? '/' + params : '/');
                    }
                })
                .catch((error: AxiosError) => {
                    globalState.setAuthUsername('');
                    globalState.setAuthenticated(false, '');
                    setMessage(error?.message);
                    setOpen(true);
                });
        } else {
            setMessage('Credentials are invalid!');
            globalState.setAuthUsername('');
            globalState.setAuthenticated(false, '');
            setOpen(true);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            {open ? toast.error(message) : <></>}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Industry Training Academy
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            onChange={(e) => {
                                setEmail(e.currentTarget.value);
                                setEmailError(false);
                            }}
                            helperText={
                                emailError ? (
                                    <FormHelperText id="component-helper-text">
                                        {
                                            'A properly formatted email address is required!'
                                        }
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )
                            }
                            error={emailError}
                            id="username"
                            label="Email"
                            name="username"
                            autoComplete="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs></Grid>
                            <Grid item>
                                <Link
                                    component="button"
                                    onClick={forgotPassword}
                                    variant="body2"
                                >
                                    Forgot Password
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
};
