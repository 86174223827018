import React from 'react';
// import { Module, ModulePage } from '../interfaces/Interfaces';
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    Modal,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import { ModulePageEditor } from './ModulePageEditor';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthPost } from '../utilities/Routing/Requests';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import { BlobServiceClient } from '@azure/storage-blob';
import ImageUploader from '../modules/ImageUploader';
import { Datagrid } from '../utilities/Datagrid';
import { AxiosResponse } from 'axios';
import {
    ApiOrganization,
    Class,
    Module,
    ModulePage,
    OrganizationUser,
    Question,
} from '../interfaces/Interfaces';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { AssignmentsTab } from '../courses/AssignmentsTab';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { CreateEditStudentModal } from './CreateEditStudentModal';
import { GradeBookDatagrid } from './GradeBookDatagrid';
import { ModulePreviewDetails } from './ModulePreviewDetails';
import SliderSwitch from '../utilities/SliderSwitch';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { DateTime } from 'luxon';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
    isStudent: boolean | undefined;
    handleToggleModulePage: (moduleId: number) => void;
    handleToggleAddModule: () => void;
    toggleGradeBookModal: (studentID?: number, moduleID?: number) => void;
    classData: Class;
    modules: Array<Module>;
    setCurrentPage: (page: number) => void;
    setViewModuleDetails: (module: number) => void;
    setClassStatus: (status: string) => void;
    getModulePages: (id: number) => Promise<Array<ModulePage>>;
    setPages: (pages: Array<ModulePage>) => void;
    pages: Array<ModulePage>;
    questions: Array<Question>;
    disableForward: boolean;
    setDisableForward: (disable: boolean) => void;
    selectedClassID: number | undefined;
    toggleBack?: () => void;
    refreshModules?: () => void;
    showStudentGradebook?: (studentID?: number, moduleID?: number) => void;
    orgList: Array<ApiOrganization>;
    getClasses: () => void;
    selectedModule?: number;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const ClassDetails = ({
    isStudent,
    handleToggleModulePage,
    handleToggleAddModule,
    toggleGradeBookModal,
    classData,
    modules,
    setCurrentPage,
    setClassStatus,
    getModulePages,
    setPages,
    pages,
    questions,
    disableForward,
    setDisableForward,
    selectedClassID,
    toggleBack,
    showStudentGradebook,
    refreshModules,
    orgList,
    getClasses,
    setViewModuleDetails,
    selectedModule,
}: Props) => {
    const globalState = useGlobalStore((state) => state);
    // const [viewModuleDetails, setViewModuleDetails] = React.useState<number>(0);
    const [editting, setEditting] = React.useState(false);
    const [editStudentModalIsOpen, setEditStudentModalIsOpen] =
        React.useState(false);
    const [title, setTitle] = React.useState(classData?.className || '');
    const [year, setYear] = React.useState(classData?.year || '');
    const [term, setTerm] = React.useState(classData?.term || '');
    const [students, setStudents] = React.useState<any>([]);
    const [orgUsers, setorgUsers] = React.useState<Array<OrganizationUser>>([]);
    const [description, setDescription] = React.useState(
        classData?.classDescription || '',
    );
    const [file, setFile] = React.useState<Blob | null>(null);
    const [modulesLocal, setModulesLocal] = React.useState<Array<Module>>();
    const [showModuleDetailsModal, setShowModuleDetailsModal] =
        React.useState<boolean>(false);
    const [showModuleDetails, setShowModuleDetails] = React.useState<Module>();

    const [startDate, setStartDate] = React.useState(
        DateTime.fromJSDate(new Date(classData?.startDate)),
    );
    const [endDate, setEndDate] = React.useState(
        DateTime.fromJSDate(new Date(classData?.endDate)),
    );

    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        setModulesLocal(modules);
    }, [modules]);

    const getOrganization = () => {
        let classStudents: any = [];
        if (globalState?.user?.orgId) {
            AuthPost(
                '/organization/organization',
                { id: globalState?.user?.orgId },
                {
                    'x-access-token': globalState?.authToken || '',
                    canCache: true,
                },
            )
                .then((res: AxiosResponse<Array<ApiOrganization>>) => {
                    // setOrganization(res?.data[0]);
                    setorgUsers(res?.data[0]?.users);
                    classStudents = Array.from(
                        new Set(
                            res?.data[0]?.users?.filter((user: any) => {
                                return user.type.includes('Student');
                            }),
                        ),
                    );
                    return classStudents;
                })
                .then((classStus) => {
                    AuthPost(
                        '/userclass/getclassusers',
                        { classID: classData.classID },
                        {
                            'x-access-token': globalState?.authToken || '',
                            canCache: true,
                        },
                    )
                        .then(
                            async (
                                res: AxiosResponse<Array<ApiOrganization>>,
                            ) => {
                                const ids = await res?.data.map(
                                    (obj: any) => obj.userID,
                                );
                                const newStudentsList = await classStus.filter(
                                    (student: any) => {
                                        if (
                                            ids.includes(student.id) &&
                                            student?.id !==
                                                globalState?.user?.id
                                        ) {
                                            return student;
                                        }
                                    },
                                );

                                if (newStudentsList?.length) {
                                    setStudents(newStudentsList);
                                }
                            },
                        )
                        .catch((err) => {
                            toast.error(err.toString());
                        });
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
    };
    React.useEffect(() => {
        getOrganization();
    }, []);

    let localImageUrl = classData?.image;
    const changeImage = (newImage: string | null) => {
        localImageUrl = newImage;
    };
    const changeTitle = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setTitle(e?.currentTarget?.value);
    };

    const toggleEditting = () => {
        // const toggleEditting = (refresh?: boolean) => {
        const current = editting;
        setEditting(!current);
    };

    const removeModuleFromClass = (moduleID: number, classID: number) => {
        AuthPost(
            '/classdetail/removeclassdetail',
            {
                classID: classID,
                moduleID: moduleID,
            },
            {
                'x-access-token': globalState.authToken,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Module Removed Successfully!');
                }
                if (refreshModules) {
                    refreshModules();
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    //Storage account credentials
    const account = globalState?.env?.account; // get the storage account name from the .env file
    const sasToken = globalState?.env?.sasToken; // get the SAS token from the .env file
    const containerName = globalState?.env?.containerName || ''; // get the container name from the .env file
    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net/?${sasToken}`,
    ); // create a blobServiceClient
    const containerClient = blobServiceClient.getContainerClient(containerName); // create a containerClient

    const blobToFile = (blob: Blob) =>
        new File([blob], 'create', {
            type: 'image/png',
            lastModified: Date.now(),
        });

    const handleSubmitImage = (fileForSubmit: Blob | null): Promise<string> => {
        if (fileForSubmit) {
            const fileToSubmit = blobToFile(fileForSubmit);
            const blobName = `${new Date().getTime()}-${
                fileToSubmit?.name || 'update.png'
            }`; // Specify a default blob name if needed
            if (!fileToSubmit) {
                // check if the file is selected
                // alert('Please select an image to upload');
                return new Promise((res) => res(''));
            }
            if (!account || !sasToken || !containerName) {
                // check if the credentials are set
                alert(
                    'Please make sure you have set the Azure Storage credentials in the .env file or as ENV Variable',
                );
                return new Promise((res) => res(''));
            }
            try {
                const blobClient = containerClient.getBlockBlobClient(blobName); // get the blob client
                return blobClient
                    .uploadData(fileToSubmit, {
                        blobHTTPHeaders: {
                            blobContentType: fileToSubmit?.type,
                        },
                    })
                    .then(() => {
                        return `https://imageslms.blob.core.windows.net/images-lms/${blobName}`;
                    });
            } catch (error) {
                console.error(error); // Handle error
            }
            return new Promise((resolve) =>
                resolve(
                    `https://imageslms.blob.core.windows.net/images-lms/${blobName}`,
                ),
            );
        } else {
            return new Promise((res) => res(''));
        }
    };

    const updateClass = async (updateBody?: Class) => {
        let data: Array<any> = [];
        if (file) {
            handleSubmitImage(file).then(async (res) => {
                await AuthPost(
                    '/class/updateclass',
                    updateBody
                        ? updateBody
                        : {
                              classID: classData?.classID,
                              className: title,
                              classDescription: description,
                              year: year,
                              term: term,
                              image: res,
                              class: classData?.class,
                              endDate: endDate.toISO(),
                              startDate: startDate.toISO(),
                          },
                    {
                        'x-access-token': globalState.authToken,
                        canCache: false,
                    },
                )
                    .then((resp) => {
                        if (resp?.status === 200) {
                            data = resp?.data;
                            getClasses();
                            return resp?.data;
                        } else {
                            return [];
                        }
                    })
                    .catch((err) => {
                        toast.error(err.toString());
                    });
                // toggleEditting(true);
                toggleEditting();
                return data;
            });
        } else {
            await AuthPost(
                '/class/updateclass',
                updateBody
                    ? updateBody
                    : {
                          classID: classData?.classID,
                          className: title,
                          classDescription: description,
                          year: year,
                          term: term,
                          image: classData?.image,
                          class: classData?.class,
                          endDate: endDate.toISO(),
                          startDate: startDate.toISO(),
                      },
                {
                    'x-access-token': globalState.authToken,
                    canCache: false,
                },
            )
                .then((resp) => {
                    if (resp?.status === 200) {
                        data = resp?.data;
                        getClasses();
                        return resp?.data;
                    } else {
                        return [];
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
            // toggleEditting(true);
            toggleEditting();
            return data;
        }
    };

    const toggleEditStudentModal = () => {
        const current = editStudentModalIsOpen;
        setEditStudentModalIsOpen(!current);
    };

    const addStudentToClass = (studentId: string) => {
        return AuthPost(
            '/userclass/adduserclass',
            { userID: studentId, classID: classData?.class },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    getClasses();
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    const bulkAddStudentToClass = (studentIds: Array<string>) => {
        return AuthPost(
            '/userclass/bulkadduserclass',
            { userIDs: studentIds, classID: classData?.class },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    getClasses();
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    const classOrg = orgList?.filter(
        (org) => org.id?.toString() === classData?.orgID?.toString(),
    )[0];
    const classInstructor = classOrg?.users?.filter(
        (user) => user.id?.toString() === classData?.instructorID?.toString(),
    )[0];

    const handleDateEdit = (updatedRow: any, originalRow: any) => {
        if (updatedRow.startDate !== originalRow.startDate) {
            AuthPost(
                '/classdetail/updateclassdetail',
                {
                    moduleID: originalRow.id,
                    classID: selectedClassID,
                    startDate: DateTime.fromJSDate(
                        new Date(updatedRow.startDate),
                    ).toString(),
                    endDate: DateTime.fromJSDate(
                        new Date(originalRow.endDate),
                    ).toString(),
                },
                {
                    'x-access-token': globalState.authToken,
                },
            )
                .then((res) => {
                    if (res?.status === 200) {
                        toast.success('Class Successfully Updated!');
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
        if (updatedRow.endDate !== originalRow.endDate) {
            AuthPost(
                '/classdetail/updateclassdetail',
                {
                    moduleID: originalRow.id,
                    classID: selectedClassID,
                    startDate: DateTime.fromJSDate(
                        new Date(originalRow.startDate),
                    ).toString(),
                    endDate: DateTime.fromJSDate(
                        new Date(updatedRow.endDate),
                    ).toString(),
                },
                {
                    'x-access-token': globalState.authToken,
                },
            )
                .then((res) => {
                    if (res?.status === 200) {
                        toast.success('Class Successfully Updated!');
                    }
                })
                .catch((err) => {
                    toast.error(err.toString());
                });
        }
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return isStudent &&
        Number(classData?.instructorID) !== Number(globalState?.user?.id) ? (
        <>
            <AssignmentsTab
                classTitle={classData?.className}
                selectedModule={selectedModule}
                questions={questions}
                modules={modules}
                pages={pages}
                currentPage={globalState?.currentPage || 0}
                setCurrentPage={setCurrentPage}
                getModulePages={getModulePages}
                setPages={setPages}
                disableForward={disableForward}
                setDisableForward={setDisableForward}
                instructorID={Number(classData?.instructorID)}
                classID={selectedClassID}
                toggleBack={toggleBack}
                showGradebook={showStudentGradebook}
                setViewModuleDetailsParent={setViewModuleDetails}
                cardView={true}
            />
        </>
    ) : (
        <ThemeProvider theme={defaultTheme}>
            {CreateEditStudentModal({
                open: editStudentModalIsOpen,
                handleClose: () => setEditStudentModalIsOpen(false),
                organization: classOrg,
                authToken: globalState.authToken,
                selectedInstructor: classInstructor,
                addStudentToClass: addStudentToClass,
                selectedStudent: null,
                allUsers: orgUsers,
                bulkAddStudentToClass: bulkAddStudentToClass,
            })}

            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Course Tabs"
            >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Roster" {...a11yProps(1)} />
                <Tab label="Gradebook" {...a11yProps(2)} />
                <Tab label="Assignments" {...a11yProps(3)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
                {showModuleDetails ? (
                    <Modal
                        open={showModuleDetailsModal}
                        onClose={() => setShowModuleDetailsModal(false)}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            outline: 'none',
                        }}
                    >
                        <ModulePreviewDetails
                            toggleModuleDetails={() =>
                                setShowModuleDetailsModal(false)
                            }
                            module={showModuleDetails}
                        />
                    </Modal>
                ) : (
                    <></>
                )}
                <Card
                    sx={{
                        margin: '16px',
                    }}
                >
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 10px',
                            // backgroundColor: '#f44336',
                            // color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'left',
                            }}
                        >
                            <IconButton
                                onClick={() => handleToggleModulePage(0)}
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{ alignSelf: 'center', textAlign: 'center' }}
                        >
                            {!editting ? (
                                classData?.className
                            ) : (
                                <TextField
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    name="title"
                                    defaultValue={classData?.className}
                                    onChange={changeTitle}
                                />
                            )}
                        </Grid>
                        {!editting ? (
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <Grid
                                    container
                                    style={{
                                        padding: '10px 10px 10px 10px',
                                        // backgroundColor: '#f44336',
                                        // color: '#fff',
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                    }}
                                >
                                    <Grid
                                        xs={6}
                                        sx={{
                                            textAlignLast: 'left',
                                        }}
                                    >
                                        {classData?.status?.length ? (
                                            <div
                                                style={{
                                                    fontWeight: 'lighter',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Closed
                                                <SliderSwitch
                                                    sx={{ m: 1 }}
                                                    checked={
                                                        classData?.status ===
                                                        'active'
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() =>
                                                        setClassStatus(
                                                            classData?.status ===
                                                                'active'
                                                                ? 'closed'
                                                                : 'active',
                                                        )
                                                    }
                                                />
                                                Active
                                            </div>
                                        ) : (
                                            <></>
                                        )}
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        sx={{
                                            textAlignLast: 'right',
                                        }}
                                    >
                                        <IconButton
                                            sx={{
                                                marginLeft: '5px',
                                                backgroundColor: '#f1c40f',
                                                border: 'solid #fff 1px',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                            title={'Edit Class'}
                                            onClick={() => toggleEditting()}
                                        >
                                            <SettingsApplicationsIcon />
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                marginLeft: '5px',
                                                backgroundColor: '#e74c3c',
                                                border: 'solid #fff 1px',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                fontSize: '20px',
                                            }}
                                            title={'Delete Class'}
                                            // onClick={() => {
                                            //     toggleDeleteModuleModal(module?.id);
                                            // }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        backgroundColor: '#f44336',
                                        '&:hover': {
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.04)',
                                            color: 'black',
                                        },
                                    }}
                                    onClick={() => updateClass()}
                                >
                                    Save
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {!editting ? (
                        <>
                            <div style={{ textAlign: 'center' }}>
                                Invite Code: {classData?.class}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                {classData?.classDescription}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                Year: {classData?.year}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                Term: {classData?.term}
                            </div>
                            {classData?.image?.length ? (
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        style={{ width: '50%' }}
                                        src={classData?.image || ''}
                                        alt="Module Details Missing Image"
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        <>
                            <TextField
                                required
                                fullWidth
                                id="description"
                                label="Description"
                                name="description"
                                defaultValue={classData?.classDescription}
                                sx={{ marginBottom: '16px' }}
                                onChange={(e) =>
                                    setDescription(e?.currentTarget?.value)
                                }
                            />
                            <TextField
                                margin="normal"
                                required
                                sx={{ width: '50%' }}
                                id="year"
                                label="Year"
                                name="year"
                                autoFocus
                                defaultValue={classData?.year}
                                onChange={(e) =>
                                    setYear(e?.currentTarget?.value)
                                }
                            />
                            <TextField
                                margin="normal"
                                required
                                sx={{ width: '50%' }}
                                id="term"
                                label="Term"
                                name="term"
                                defaultValue={classData?.term}
                                onChange={(e) =>
                                    setTerm(e?.currentTarget?.value)
                                }
                            />
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                sx={{ width: '50%' }}
                                onChange={(newValue: any) => {
                                    setStartDate(newValue);
                                }}
                            />
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue: any) => {
                                    setEndDate(newValue);
                                }}
                                sx={{ width: '50%' }}
                            />
                            <ImageUploader
                                setFile={(newFile) => setFile(newFile)}
                                imageUrl={localImageUrl}
                                file={file}
                                changeUrl={changeImage}
                                deleteCallback={() =>
                                    updateClass({
                                        ...classData,
                                        className: title,
                                        classDescription: description,
                                        year: year,
                                        term: term,
                                        image: '',
                                    })
                                }
                            />
                        </>
                    )}
                </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <Card
                    style={{
                        margin: '16px',
                    }}
                >
                    <Grid container sx={{ padding: '16px' }}>
                        <Grid
                            xs={4}
                            sx={{
                                margin: '0',
                                fontFamily:
                                    'Roboto, Helvetica, Arial, sans-serif',
                                fontWeight: '400',
                                fontSize: '1.5rem',
                                lineHeight: '1.334',
                                letterSpacing: '0em',
                                display: 'block',
                                textAlignLast: 'left',
                                aligntContent: 'center',
                            }}
                        >
                            Roster
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{
                                alignSelf: 'center',
                                textAlign: 'center',
                            }}
                        ></Grid>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            <IconButton
                                title={'Add Student'}
                                sx={{
                                    marginLeft: '5px',
                                    backgroundColor: 'green',
                                    border: 'solid #fff 1px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                }}
                                onClick={toggleEditStudentModal}
                            >
                                <PersonAddAlt1Icon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid xs={12} sx={{ alignSelf: 'center' }}>
                        {Datagrid({
                            sx: { height: 'unset' },
                            // rowModesModel: rowModesModel,
                            editMode: 'row',
                            columns: [
                                {
                                    field: 'id',
                                    headerName: 'ID',
                                    type: 'string',
                                    flex: 0.25,
                                },
                                {
                                    field: 'firstName',
                                    headerName: 'First Name',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'lastName',
                                    headerName: 'Last Name',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'userEmail',
                                    headerName: 'Email',
                                    type: 'string',
                                    flex: 1,
                                },
                            ],
                            data: students,
                        })}
                    </Grid>
                </Card>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
                <GradeBookDatagrid
                    classID={selectedClassID}
                    classStudents={students}
                    modules={modules}
                    toggleGradebookModal={toggleGradeBookModal}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                <Card
                    style={{
                        margin: '16px',
                    }}
                >
                    <Grid container sx={{ padding: '16px' }}>
                        <Grid
                            xs={4}
                            sx={{
                                margin: '0',
                                fontFamily:
                                    'Roboto, Helvetica, Arial, sans-serif',
                                fontWeight: '400',
                                fontSize: '1.5rem',
                                lineHeight: '1.334',
                                letterSpacing: '0em',
                                display: 'block',
                                textAlignLast: 'left',
                                aligntContent: 'center',
                            }}
                        >
                            Assignments
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{
                                alignSelf: 'center',
                                textAlign: 'center',
                            }}
                        ></Grid>
                        <Grid
                            xs={4}
                            sx={{
                                textAlignLast: 'right',
                            }}
                        >
                            <IconButton
                                title={'Add Module'}
                                sx={{
                                    marginLeft: '5px',
                                    backgroundColor: 'green',
                                    border: 'solid #fff 1px',
                                    color: '#fff',
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                }}
                                onClick={handleToggleAddModule}
                            >
                                <LibraryAddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div>
                        {Datagrid({
                            sx: { height: 'unset' },
                            // rowModesModel: rowModesModel,
                            editMode: 'row',
                            processRowUpdate: handleDateEdit,
                            columns: [
                                {
                                    field: 'code',
                                    headerName: 'Code',
                                    type: 'string',
                                    flex: 0.25,
                                },
                                {
                                    field: 'title',
                                    headerName: 'Title',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'summary',
                                    headerName: 'Summary',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'startDate',
                                    headerName: 'Start Date',
                                    type: 'date',
                                    flex: 1,
                                    editable: true,
                                    valueFormatter: (params) => {
                                        const date = new Date(
                                            DateTime.fromISO(
                                                params.value as any,
                                            ).toJSDate(),
                                        );
                                        const month = date.getMonth() + 1;
                                        const day = date.getDate();
                                        const startYear = date.getFullYear();
                                        return `${month
                                            .toString()
                                            .padStart(2, '0')}/${day
                                            .toString()
                                            .padStart(2, '0')}/${startYear}`;
                                    },
                                },
                                {
                                    field: 'endDate',
                                    headerName: 'endDate',
                                    type: 'date',
                                    flex: 1,
                                    editable: true,
                                    valueFormatter: (params) => {
                                        const date = new Date(
                                            DateTime.fromISO(
                                                params.value as any,
                                            ).toJSDate(),
                                        );
                                        const month = date.getMonth() + 1;
                                        const day = date.getDate();
                                        const endYear = date.getFullYear();
                                        return `${month
                                            .toString()
                                            .padStart(2, '0')}/${day
                                            .toString()
                                            .padStart(2, '0')}/${endYear}`;
                                    },
                                },
                                {
                                    field: 'actions',
                                    type: 'string',
                                    headerName: 'Actions',
                                    flex: 0.75,
                                    renderCell: (rowData) => {
                                        return (
                                            <>
                                                <IconButton
                                                    sx={{
                                                        border: 'solid #fff 1px',
                                                        color: 'green',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}
                                                    title="View Details"
                                                    onClick={() => {
                                                        setShowModuleDetails(
                                                            rowData.row,
                                                        );
                                                        setShowModuleDetailsModal(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    <ListAltIcon />
                                                </IconButton>
                                                <IconButton
                                                    sx={{
                                                        border: 'solid #fff 1px',
                                                        color: '#e74c3c',
                                                        fontWeight: 'bold',
                                                        fontSize: '20px',
                                                    }}
                                                    title="Remove"
                                                    onClick={() => {
                                                        removeModuleFromClass(
                                                            rowData.row.id,
                                                            classData.classID,
                                                        );
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        );
                                    },
                                },
                            ],
                            data: modulesLocal || [],
                        })}
                    </div>
                </Card>
            </CustomTabPanel>
        </ThemeProvider>
    );
};

export { ClassDetails };
