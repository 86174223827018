import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import { Distributor, GlobalState } from '../interfaces/Interfaces';
import { Card, Grid, IconButton } from '@mui/material';
import { Datagrid } from '../utilities/Datagrid';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { AuthGet } from '../utilities/Routing/Requests';
import { AxiosResponse } from 'axios';
import { useGlobalStore } from '../utilities/GlobalState';
import { toast } from 'react-toastify';
import { CreateDistributorModal } from './CreateDistributorModal';

export const Distributors = () => {
    const [distributors, setDistributors] = React.useState<Array<Distributor>>(
        [],
    );
    const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);

    const globalState = useGlobalStore((state: GlobalState) => state);

    const getDistributors = () => {
        AuthGet('/organization/distributorlist', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<Distributor>>) => {
                setDistributors(res?.data);
                return res?.data;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        getDistributors();
    }, []);

    return (
        <ThemeProvider theme={defaultTheme}>
            {CreateDistributorModal({
                open: createModalIsOpen,
                handleClose: () => setCreateModalIsOpen(false),
                refreshDistributorList: getDistributors,
            })}
            <Box
                sx={{
                    padding: '30px 10px 10px 10px',
                }}
            >
                <Card sx={{ padding: '10px', margin: '10px' }}>
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 20px',
                            backgroundColor: `${defaultTheme.palette.primary.main}`,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        <Grid item xs={8} sx={{ alignSelf: 'center' }}>
                            <div>Distributor Management</div>
                        </Grid>
                        {globalState?.user?.type?.includes('APTAdmin') ? (
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <IconButton
                                    key={'blog-grid-slider-add-iconbutton'}
                                    title="Create Distributor"
                                    onClick={() =>
                                        setCreateModalIsOpen(!createModalIsOpen)
                                    }
                                >
                                    <DomainAddIcon
                                        sx={{
                                            color: '#fff',
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            />
                        )}
                    </Grid>
                    {Datagrid({
                        columns: [
                            {
                                field: 'id',
                                headerName: 'ID',
                                type: 'string',
                                flex: 0.25,
                            },
                            {
                                field: 'name',
                                headerName: 'Name',
                                type: 'string',
                                flex: 1,
                            },
                            {
                                field: 'contactName',
                                headerName: 'Contact',
                                type: 'string',
                                flex: 1,
                                // editable: true,
                            },
                            {
                                field: 'email',
                                headerName: 'Email',
                                type: 'string',
                                flex: 1,
                                // editable: true,
                            },
                            {
                                field: 'active',
                                headerName: 'Active',
                                type: 'boolean',
                                flex: 0.25,
                                // editable: true,
                            },
                            // {
                            //     field: 'action',
                            //     headerName: 'Action',
                            //     flex: 1,
                            //     sortable: false,
                            //     renderCell: (params) => {
                            //         return (
                            //             <>
                            //                 <IconButton
                            //                     sx={{
                            //                         backgroundColor: 'green',
                            //                         border: 'solid #fff 1px',
                            //                         color: '#fff',
                            //                         fontWeight: 'bold',
                            //                         fontSize: '20px',
                            //                     }}
                            //                     onClick={() =>
                            //                         toggleDetailsModal(
                            //                             params?.row,
                            //                         )
                            //                     }
                            //                 >
                            //                     <ListAltIcon />
                            //                 </IconButton>
                            //                 <IconButton
                            //                     sx={{
                            //                         marginLeft: '5px',
                            //                         backgroundColor: '#f1c40f',
                            //                         border: 'solid #fff 1px',
                            //                         color: '#fff',
                            //                         fontWeight: 'bold',
                            //                         fontSize: '20px',
                            //                     }}
                            //                     onClick={() =>
                            //                         toggleEditModal(params?.row)
                            //                     }
                            //                 >
                            //                     <SettingsApplicationsIcon />
                            //                 </IconButton>
                            //             </>
                            //         );
                            //     },
                            // },
                        ],
                        data: distributors,
                    })}
                </Card>
            </Box>
        </ThemeProvider>
    );
};
