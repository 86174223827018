import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import {
    EnvVars,
    GlobalState,
    OrganizationUser,
} from '../interfaces/Interfaces';

export const useGlobalStore = create<GlobalState>()(
    devtools(
        persist(
            (set) => ({
                env: undefined,
                setEnv: (envs: EnvVars) => {
                    return set(() => ({
                        env: envs,
                    }));
                },
                authenticated: false,
                setAuthenticated: (authed: boolean, username: string) => {
                    setTimeout(() => {
                        useGlobalStore.persist.clearStorage();
                    }, 18000000);
                    return set(() => ({
                        authenticated: authed,
                        username: username,
                    }));
                },
                currentPage: 1,
                setCurrentPage: (page: number) =>
                    set(() => ({ currentPage: page })),
                currentClass: undefined,
                setCurrentClass: (curClass: number | undefined) =>
                    set(() => ({ currentClass: curClass })),
                currentModule: undefined,
                setCurrentModule: (curModule: number | undefined) =>
                    set(() => ({ currentModule: curModule })),
                user: undefined,
                setUser: (newUser: OrganizationUser) =>
                    set(() => ({ user: newUser })),
                authToken: '',
                setAuthToken: (token: string) =>
                    set(() => ({ authToken: token })),
                authUsername: '',
                setAuthUsername: (userName: string) =>
                    set(() => ({ authUsername: userName })),
                classes: undefined,
                setClasses: (classes: Array<string | undefined> | undefined) =>
                    set(() => ({ classes: classes })),
                isAdmin: false,
                setIsAdmin: (isAdmin: boolean) =>
                    set(() => ({ isAdmin: isAdmin })),
                clearState: () =>
                    set(() => ({
                        authenticated: false,
                        authToken: '',
                        authUsername: '',
                        customerId: '',
                        customer: null,
                        isAdmin: false,
                    })),
            }),
            {
                name: 'ita-global',
                storage: createJSONStorage(() => sessionStorage),
            },
        ),
    ),
);
