import React from 'react';
import '../styles/Maintenance.css';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Grid } from '@mui/material';

interface Props {
    isMaintenanceMode: boolean;
    handleClose: () => void;
}

const MaintenanceBanner = ({ isMaintenanceMode, handleClose }: Props) => {
    if (!isMaintenanceMode) {
        return <></>;
    }

    return (
        <div className="maintenance-banner">
            <div className="banner-content">
                <Grid container sx={{ paddingTop: '3px' }}>
                    <Grid md={2}>
                        <ConstructionIcon />
                    </Grid>
                    <Grid md={8}>
                        <div style={{ marginTop: '3px' }}>
                            Our website is currently under maintenance. We
                            apologize for any inconvenience.
                        </div>
                    </Grid>
                    <Grid md={1}>
                        <ConstructionIcon />
                    </Grid>
                    <Grid md={1}>
                        <button onClick={handleClose}>X</button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default MaintenanceBanner;
