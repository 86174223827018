import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '../utilities/Utilites';
import * as React from 'react';
import Box from '@mui/system/Box';
import {
    ApiOrganization,
    Distributor,
    GlobalState,
    OrganizationUser,
} from '../interfaces/Interfaces';
import { Card, Grid, IconButton } from '@mui/material';
import { CreateOrganizationModal } from './CreateOrganizationModal';
import { EditOrganizationModal } from './EditOrganizationModal';
import { Datagrid } from '../utilities/Datagrid';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// import DeleteIcon from '@mui/icons-material/Delete';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { OrganizationDetailsModal } from './OrganizationDetailsModal';
import { EditInstructorModal } from '../instructors/EditInstructorModal';
import { StudentManagementModal } from '../classes/StudentManagementModal';
import { AuthGet } from '../utilities/Routing/Requests';
import { AxiosResponse } from 'axios';
import { useGlobalStore } from '../utilities/GlobalState';
import { AddInstructorModal } from './AddInstructorModal';
import { AddContactModal } from './AddContactModal';
import { CreateEditStudentModal } from '../classes/CreateEditStudentModal';
import { CsvImporter } from '../utilities/CsvImporter/CsvImporter';
import { toast } from 'react-toastify';
import { GetCity, GetState } from 'react-country-state-city';

export const Organizations = () => {
    const [loading] = React.useState(false);

    const [selectedOrganization, setSelectedOrganization] =
        React.useState<ApiOrganization>();
    const [organizations, setOrganizations] = React.useState<
        Array<ApiOrganization>
    >([]);
    const [createModalIsOpen, setCreateModalIsOpen] = React.useState(false);
    const [detailsModalIsOpen, setDetailsModalIsOpen] = React.useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = React.useState(false);
    const [addInstructorModalIsOpen, setAddInstructorModalIsOpen] =
        React.useState(false);
    const [addContactModalIsOpen, setAddContactModalIsOpen] =
        React.useState(false);
    const [selectedStudents, setSelectedStudents] = React.useState<
        Array<OrganizationUser> | undefined
    >(undefined);
    const [selectedInstructor, setSelectedInstructor] =
        React.useState<OrganizationUser | null>(null);
    const [instructors, setInstructors] = React.useState<
        Array<OrganizationUser>
    >([]);
    const [selectedStudent, setSelectedStudent] =
        React.useState<OrganizationUser | null>(null);
    const [editInstructorModalIsOpen, setEditInstructorModalIsOpen] =
        React.useState(false);
    const [editStudentModalIsOpen, setEditStudentModalIsOpen] =
        React.useState(false);
    const [bulkAddStudentModalIsOpen, setBulkAddStudentModalIsOpen] =
        React.useState(false);
    const [studentManagementModalIsOpen, setStudentManagementModalIsOpen] =
        React.useState(false);
    const [stateList, setStateList] = React.useState([]);
    const [distributorList, setDistributorList] = React.useState<
        Array<Distributor>
    >([]);
    const [cityList, setCityList] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState<any>();
    const [selectedCity, setSelectedCity] = React.useState<any>();
    const [selectedDistributor, setSelectedDistributor] =
        React.useState<Distributor>();

    const globalState = useGlobalStore((state: GlobalState) => state);

    const getDistributors = () => {
        AuthGet('/organization/distributorlist', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<Distributor>>) => {
                setDistributorList(res?.data);
                return res?.data;
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const getOrganizations = (selectedOrg?: string) => {
        AuthGet('/organization/orglist', {
            'x-access-token': globalState?.authToken || '',
            canCache: true,
        })
            .then((res: AxiosResponse<Array<ApiOrganization>>) => {
                const orgList = res?.data?.filter((orgs) => {
                    return orgs?.id?.toString() === globalState?.user?.orgId;
                });
                if (!globalState?.user?.type?.includes('APTAdmin')) {
                    setOrganizations(orgList);
                    if (selectedOrg) {
                        const organization = orgList.filter(
                            (org) => org.id === selectedOrg,
                        )[0];
                        setSelectedOrganization(organization);
                    }
                    return orgList;
                } else {
                    setOrganizations(res?.data);
                    GetState(233).then((result: any) => {
                        setStateList(result);
                        return result;
                    });
                    if (selectedOrg) {
                        const organization = res?.data.filter(
                            (org) => org.id === selectedOrg,
                        )[0];
                        setSelectedOrganization(organization);
                    }
                    return res?.data;
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    React.useEffect(() => {
        getOrganizations();
        getDistributors();
    }, []);
    React.useEffect(() => {
        setInstructors(
            selectedOrganization?.users?.filter((user) => {
                return user?.type?.includes('Instructor') && user?.active;
            }) || [],
        );
    }, [selectedOrganization]);
    const toggleEditModal = (organization?: ApiOrganization | null) => {
        const current = editModalIsOpen;
        if (organization) {
            const currentState: any = stateList.filter(
                (state: any) => state?.state_code === organization?.state,
            )[0];
            setSelectedState(currentState);
            if (!cityList?.length) {
                GetCity(233, currentState?.id)
                    .then((result: any) => {
                        setCityList(result);
                        return result;
                    })
                    .then((list) => {
                        const currentCity = list.filter(
                            (city: any) => city?.name === organization?.city,
                        )[0];
                        setSelectedCity(currentCity);
                    })
                    .then(() => {
                        setSelectedOrganization(organization);
                    });
            }
        }
        setEditModalIsOpen(!current);
    };
    const toggleAddInstuctorModal = () => {
        const current = addInstructorModalIsOpen;
        setAddInstructorModalIsOpen(!current);
    };
    const toggleAddContactModal = () => {
        const current = addContactModalIsOpen;
        setAddContactModalIsOpen(!current);
    };

    const toggleEditInstructorModal = (
        instructor?: OrganizationUser | null,
    ) => {
        const current = editInstructorModalIsOpen;
        if (instructor) {
            setSelectedInstructor(instructor);
        }
        setEditInstructorModalIsOpen(!current);
    };

    const toggleEditStudentModal = (student: OrganizationUser | null) => {
        const current = editStudentModalIsOpen;
        setSelectedStudent(student);
        setEditStudentModalIsOpen(!current);
    };

    const toggleBulkAddStudentModal = () => {
        const current = bulkAddStudentModalIsOpen;
        setEditStudentModalIsOpen(false);
        setBulkAddStudentModalIsOpen(!current);
    };

    const toggleStudentManagementModal = (
        students: Array<OrganizationUser> | null,
        professor: OrganizationUser | null,
    ) => {
        const current = studentManagementModalIsOpen;
        if (students) {
            setSelectedStudents(students);
        }
        setSelectedInstructor(professor);
        setStudentManagementModalIsOpen(!current);
    };

    const toggleDetailsModal = (organization?: ApiOrganization | null) => {
        const current = detailsModalIsOpen;
        if (organization) {
            setSelectedOrganization(organization);
        }
        setDetailsModalIsOpen(!current);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                sx={{
                    padding: '30px 10px 10px 10px',
                }}
            >
                {!loading && selectedOrganization && (
                    <CsvImporter
                        open={bulkAddStudentModalIsOpen}
                        handleClose={toggleBulkAddStudentModal}
                        orgId={selectedOrganization.id}
                        authToken={globalState.authToken}
                    />
                )}
                {!loading &&
                    CreateOrganizationModal({
                        open: createModalIsOpen,
                        handleClose: () => setCreateModalIsOpen(false),
                        refreshOrgList: getOrganizations,
                        stateList: stateList,
                        cityList: cityList,
                        setCityList: setCityList,
                        selectedCity: selectedCity,
                        selectedState: selectedState,
                        setSelectedState: setSelectedState,
                        setSelectedCity: setSelectedCity,
                        distributorList: distributorList,
                        setSelectedDistributor: setSelectedDistributor,
                        selectedDistributor: selectedDistributor,
                    })}
                {!loading &&
                    // selectedStudents &&
                    StudentManagementModal({
                        open: studentManagementModalIsOpen,
                        toggleModal: toggleStudentManagementModal,
                        students: selectedStudents,
                        toggleEditStudentModal: toggleEditStudentModal,
                        toggleBulkAddStudentModal: toggleBulkAddStudentModal,
                        selectedInstructor: selectedInstructor,
                        authToken: globalState.authToken,
                    })}
                {!loading &&
                    selectedInstructor &&
                    EditInstructorModal({
                        open: editInstructorModalIsOpen,
                        handleClose: toggleEditInstructorModal,
                        instructor: selectedInstructor,
                    })}
                {organizations?.length &&
                    selectedOrganization &&
                    !loading &&
                    EditOrganizationModal({
                        open: editModalIsOpen,
                        handleClose: () => {
                            setEditModalIsOpen(false);
                            setDetailsModalIsOpen(false);
                        },
                        organization: selectedOrganization,
                        refreshOrgList: getOrganizations,
                        authToken: globalState?.authToken,
                        stateList: stateList,
                        cityList: cityList,
                        setCityList: setCityList,
                        selectedCity: selectedCity,
                        selectedState: selectedState,
                        setSelectedState: setSelectedState,
                        setSelectedCity: setSelectedCity,
                        distributorList: distributorList,
                        setSelectedDistributor: setSelectedDistributor,
                        selectedDistributor: selectedDistributor,
                    })}
                {organizations?.length &&
                    selectedOrganization &&
                    !loading &&
                    AddInstructorModal({
                        open: addInstructorModalIsOpen,
                        handleClose: () => {
                            setAddInstructorModalIsOpen(false);
                            getOrganizations(selectedOrganization.id);
                        },
                        organization: selectedOrganization,
                        authToken: globalState.authToken,
                    })}
                {organizations?.length &&
                    selectedOrganization &&
                    !loading &&
                    AddContactModal({
                        open: addContactModalIsOpen,
                        handleClose: () => setAddContactModalIsOpen(false),
                        organization: selectedOrganization,
                        authToken: globalState.authToken,
                    })}
                {organizations?.length &&
                    selectedOrganization &&
                    editStudentModalIsOpen &&
                    !loading &&
                    editStudentModalIsOpen &&
                    CreateEditStudentModal({
                        open: editStudentModalIsOpen,
                        handleClose: () => setEditStudentModalIsOpen(false),
                        organization: selectedOrganization,
                        authToken: globalState.authToken,
                        selectedInstructor: selectedInstructor,
                        selectedStudent: selectedStudent,
                    })}
                {organizations?.length &&
                    selectedOrganization &&
                    !loading &&
                    OrganizationDetailsModal(
                        selectedOrganization,
                        toggleEditModal,
                        toggleAddInstuctorModal,
                        toggleAddContactModal,
                        detailsModalIsOpen,
                        toggleDetailsModal,
                        toggleEditInstructorModal,
                        toggleStudentManagementModal,
                        globalState.authToken,
                        instructors,
                        toggleBulkAddStudentModal,
                        distributorList,
                    )}

                <Card sx={{ padding: '10px', margin: '10px' }}>
                    <Grid
                        container
                        style={{
                            padding: '10px 10px 10px 20px',
                            backgroundColor: `${defaultTheme.palette.primary.main}`,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '20px',
                        }}
                    >
                        <Grid item xs={8} sx={{ alignSelf: 'center' }}>
                            <div>Organization Management</div>
                        </Grid>
                        {globalState?.user?.type?.includes('APTAdmin') ? (
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            >
                                <IconButton
                                    key={'blog-grid-slider-add-iconbutton'}
                                    title="Create Organization"
                                    onClick={() =>
                                        setCreateModalIsOpen(!createModalIsOpen)
                                    }
                                >
                                    <DomainAddIcon
                                        sx={{
                                            color: '#fff',
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        ) : (
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    textAlignLast: 'right',
                                }}
                            />
                        )}
                    </Grid>
                    {Datagrid({
                        columns: [
                            {
                                field: 'id',
                                headerName: 'ID',
                                type: 'string',
                                flex: 0.25,
                            },
                            {
                                field: 'name',
                                headerName: 'Name',
                                type: 'string',
                                flex: 1,
                            },
                            {
                                field: 'parent',
                                headerName: 'Parent',
                                type: 'string',
                                flex: 1,
                                // editable: true,
                            },
                            {
                                field: 'reseller',
                                headerName: 'Reseller',
                                type: 'string',
                                flex: 1,
                                // editable: true,
                                renderCell: (params) => {
                                    const id = params?.row?.reseller;
                                    const distName = distributorList?.filter(
                                        (dist) => {
                                            return dist?.id.toString() === id;
                                        },
                                    )[0]?.name;
                                    return <>{distName}</>;
                                },
                            },
                            {
                                field: 'phone',
                                headerName: 'Phone',
                                type: 'string',
                                flex: 1,
                                // editable: true,
                            },
                            {
                                field: 'active',
                                headerName: 'Active',
                                type: 'boolean',
                                flex: 0.25,
                                // editable: true,
                            },
                            {
                                field: 'action',
                                headerName: 'Action',
                                flex: 1,
                                sortable: false,
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <IconButton
                                                sx={{
                                                    backgroundColor: 'green',
                                                    border: 'solid #fff 1px',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                                onClick={() =>
                                                    toggleDetailsModal(
                                                        params?.row,
                                                    )
                                                }
                                            >
                                                <ListAltIcon />
                                            </IconButton>
                                            <IconButton
                                                sx={{
                                                    marginLeft: '5px',
                                                    backgroundColor: '#f1c40f',
                                                    border: 'solid #fff 1px',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                                onClick={() =>
                                                    toggleEditModal(params?.row)
                                                }
                                            >
                                                <SettingsApplicationsIcon />
                                            </IconButton>
                                            {/* <IconButton
                                                sx={{
                                                    marginLeft: '5px',
                                                    backgroundColor: '#e74c3c',
                                                    border: 'solid #fff 1px',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    fontSize: '20px',
                                                }}
                                                onClick={toggleEditModal}
                                            >
                                                <DeleteIcon />
                                            </IconButton> */}
                                        </>
                                    );
                                },
                            },
                        ],
                        data: organizations,
                    })}
                </Card>
            </Box>
        </ThemeProvider>
    );
};
