import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PasswordIcon from '@mui/icons-material/Password';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import { Copyright, defaultTheme } from '../utilities/Utilites';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { FormHelperText } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import customHistory from '../utilities/Routing/History';
import { useGlobalStore } from '../utilities/GlobalState';
export const PasswordReset = () => {
    const [queryParameters] = useSearchParams();
    const globalState = useGlobalStore((state) => state);
    const email = queryParameters.get('id');
    const token = queryParameters.get('token');

    const [input, setInput] = React.useState({
        password: '',
        confirmPassword: '',
    });

    const [error, setError] = React.useState({
        password: '',
        confirmPassword: '',
    });

    const validateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setError((prev) => {
            const stateObj: any = { ...prev, [name]: '' };

            switch (name) {
                case 'password':
                    if (!value) {
                        stateObj[name] = 'Please enter New Password.';
                    } else if (
                        input.confirmPassword &&
                        value !== input.confirmPassword
                    ) {
                        stateObj.confirmPassword =
                            'New Password and Confirmation Password do not match.';
                    } else {
                        stateObj.confirmPassword = input.confirmPassword
                            ? ''
                            : error.confirmPassword;
                    }
                    break;

                case 'confirmPassword':
                    if (!value) {
                        stateObj[name] = 'Please enter Confirmation Password.';
                    } else if (input.password && value !== input.password) {
                        stateObj[name] =
                            'New Password and Confirmation Password do not match.';
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
        validateInput(e);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = JSON.stringify({
            Token: token || '',
            Email: email || globalState?.user?.userEmail,
            Password: input.password,
        });
        AuthPost('/user/resetPassword', body)
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Password Changed Successfully');
                    customHistory.replace('/login');
                } else {
                    toast.error('Error: ' + res?.statusText);
                }
            })
            .catch((e) => {
                toast.error('Error: ' + e);
            });
    };
    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary' }}>
                        <PasswordIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Industry Training Academy - Password Reset
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            onChange={onInputChange}
                            helperText={
                                error?.password?.length ? (
                                    <FormHelperText id="component-helper-text">
                                        {error?.password}
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )
                            }
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirmation Password"
                            type="password"
                            onChange={onInputChange}
                            id="confirmPassword"
                            helperText={
                                error?.confirmPassword?.length ? (
                                    <FormHelperText id="component-helper-text">
                                        {error?.confirmPassword}
                                    </FormHelperText>
                                ) : (
                                    <></>
                                )
                            }
                        />
                        <Button
                            type="submit"
                            disabled={
                                input?.password?.length < 1 ||
                                input?.confirmPassword?.length < 1 ||
                                error?.confirmPassword?.length > 0 ||
                                error?.password?.length > 0
                            }
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Submit
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
};
