import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Card, CssBaseline, TextField } from '@mui/material';
import { AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { useGlobalStore } from '../utilities/GlobalState';

export interface Props {
    open: boolean;
    handleClose: (refresh?: boolean) => void;
    refreshDistributorList: () => void;
}

export const CreateDistributorModal = ({
    open,
    handleClose,
    refreshDistributorList,
}: Props) => {
    const globalState = useGlobalStore((state) => state);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            name: data.get('name'),
            contactName: data.get('contactName'),
            email: data.get('email'),
            active: true,
        };
        AuthPost('/organization/createdistributor', body, {
            'x-access-token': globalState.authToken,
        })
            .then((res) => {
                if (res?.status === 200) {
                    toast.success('Distributor Successfully Created!');
                    refreshDistributorList();
                    handleClose(true);
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };
    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Card sx={{ maxWidth: '800px', margin: '10px', overflow: 'auto' }}>
                <CssBaseline />
                <Box
                    sx={{
                        margin: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Create Distributor
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Distributor Name"
                            name="name"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="contactName"
                            label="Contact Name"
                            name="contactName"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            CREATE DISTRIBUTOR
                        </Button>
                    </Box>
                </Box>
            </Card>
        </Modal>
    );
};
