import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
    createBulletedList,
    defaultTheme,
    formatDateNoTimeMonth,
} from '../utilities/Utilites';
import Box from '@mui/system/Box';
import { Button, Card, Grid, TextField } from '@mui/material';
import packageJson from '../../package.json';
import '../styles/UserAgentInfo.css';
import { getUserAgentDetails } from '../utilities/Utilites';
import { useGlobalStore } from '../utilities/GlobalState';
import { GlobalState } from '../interfaces/Interfaces';
import { AuthGet, AuthPost } from '../utilities/Routing/Requests';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';

export const About = () => {
    const [details, setDetails] = useState<any>({});
    const [changes, setChanges] = useState<string>('');
    const [allChanges, setAllChanges] = useState<Array<string> | null>(null);
    const globalState: any = useGlobalStore((state: GlobalState) => state);

    const getChangeNotes = () => {
        return AuthGet('/changenotes/getchangenotes', {
            'x-access-token': globalState.authToken,
            canCache: false,
        })
            .then((res) => {
                setAllChanges(res?.data || []);
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    useEffect(() => {
        const userDetails = getUserAgentDetails();
        if (allChanges === null) {
            getChangeNotes();
        }
        setDetails(userDetails);
    }, []);

    const submit = () => {
        return AuthPost(
            '/changenotes/addchangenote',
            {
                version: packageJson.version,
                changes: changes,
                buildDate: new Date().toISOString(),
            },
            {
                'x-access-token': globalState.authToken,
                canCache: true,
            },
        )
            .then((res) => {
                if (res?.status === 200) {
                    getChangeNotes();
                    return res.data;
                } else {
                    return [];
                }
            })
            .catch((err) => {
                toast.error(err.toString());
            });
    };

    const changeNotesInputs = () => {
        return (
            <>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="changes"
                    label="Changes"
                    name="changes"
                    multiline={true}
                    minRows={3}
                    autoFocus
                    onChange={(e) => {
                        setChanges(e.currentTarget.value);
                    }}
                />
                <Button
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={submit}
                >
                    SUBMIT
                </Button>
            </>
        );
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box
                sx={{
                    padding: '30px 10px 10px 10px',
                    margin: '10px 10px 10px 10px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Card
                    sx={{
                        maxWidth: '100%',
                        padding: '20px',
                        textAlign: 'center',
                    }}
                >
                    <h2>{'About Industry Training Academy'}</h2>
                    <div className="user-agent-info">
                        <Grid container sx={{ margin: 0 }} spacing={2}>
                            <Grid
                                sx={{
                                    padding: '16px',
                                    margin: 'auto',
                                }}
                                xs={6}
                                md={6}
                            >
                                <div className="info-card">
                                    <h2>Software</h2>
                                    {`Version: ${packageJson.version}`}
                                    <p></p>
                                    <h2>Browser</h2>
                                    <p>Name: {details?.browser?.name}</p>
                                    <p>Version: {details?.browser?.version}</p>
                                    <p>Major: {details?.browser?.major}</p>
                                    <p>
                                        Type: {details?.browser?.type || 'N/A'}
                                    </p>
                                    <p></p>
                                    <h2>Engine</h2>
                                    <p>Name: {details?.engine?.name}</p>
                                    <p>Version: {details?.engine?.version}</p>
                                </div>
                            </Grid>
                            <Grid
                                sx={{ padding: '16px', margin: 'auto' }}
                                md={6}
                                xs={6}
                            >
                                <div className="info-card">
                                    <h2>Operating System</h2>
                                    <p>Name: {details?.os?.name}</p>
                                    <p>Version: {details?.os?.version}</p>
                                    <p></p>
                                    <h2>Device</h2>
                                    <p>
                                        Model: {details?.device?.model || 'N/A'}
                                    </p>
                                    <p>
                                        Type: {details?.device?.type || 'N/A'}
                                    </p>
                                    <p>
                                        Vendor:{' '}
                                        {details?.device?.vendor || 'N/A'}
                                    </p>
                                    <p></p>
                                    <h2>CPU</h2>
                                    <p>
                                        Architecture:{' '}
                                        {details?.cpu?.architecture}
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: 0 }} spacing={2}>
                            <Grid
                                sx={{
                                    margin: 'auto',
                                }}
                                xs={12}
                                md={12}
                            >
                                <h2>Release Notes</h2>
                                {globalState?.user?.type?.includes(
                                    'APTAdmin',
                                ) ? (
                                    changeNotesInputs()
                                ) : (
                                    <></>
                                )}

                                {/*------------------- refresh ON SUBMIT and seperate change notes cards*/}
                                <Grid container>
                                    {allChanges && allChanges?.length ? (
                                        allChanges?.map(
                                            (
                                                changeNote: any,
                                                index: number,
                                            ) => {
                                                const date = DateTime.fromISO(
                                                    changeNote?.buildDate as any,
                                                ) as any;
                                                return (
                                                    <Grid
                                                        sx={{
                                                            padding: '16px',
                                                            // margin: 'auto',
                                                        }}
                                                        xs={6}
                                                        md={6}
                                                        key={
                                                            'changenotes-card-' +
                                                            index
                                                        }
                                                    >
                                                        <div className="info-card">
                                                            <br />
                                                            <p>
                                                                Version:{' '}
                                                                {
                                                                    changeNote?.version
                                                                }
                                                                {' - '}
                                                                {formatDateNoTimeMonth(
                                                                    date,
                                                                )}
                                                            </p>
                                                            <div
                                                                style={{
                                                                    textAlign:
                                                                        'left',
                                                                }}
                                                            >
                                                                {createBulletedList(
                                                                    changeNote?.changes,
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            },
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Box>
        </ThemeProvider>
    );
};
